import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import Routes from 'Routes';
import './Vendor';
import './components/Ripple/Ripple.init.js';
import Core from './components/Core/Core';
import Bootstrap from './components/Bootstrap/Bootstrap';
import Common from './components/Common/Common';
import Colors from './components/Colors/Colors';
import FloatButton from './components/FloatButton/FloatButton';
import Utils from './components/Utils/Utils';
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faInfo, faEdit, faLock, faSave, faArrowLeft, faPlus, faCog, 
  faChartArea, faChevronLeft, faChevronRight, faDollarSign, faClock
} from '@fortawesome/free-solid-svg-icons'

library.add(
  faInfo, faEdit, faLock, faSave, faArrowLeft, faPlus, faCog,
  faChartArea, faChevronLeft, faChevronRight, faDollarSign, faClock
);

const App = () => {
  return (
    <BrowserRouter>
      <Routes />
    </BrowserRouter>
  );
};

export default App;
