import React from "react";
import { Link, withRouter, Redirect } from "react-router-dom";
import { inject, observer } from 'mobx-react';
import { Button, Input, UncontrolledCollapse } from "reactstrap";
import PropTypes from "prop-types";
import ListErrors from 'components/ListErrors';
import FormValidator from "components/Forms/Validator.js";
import "components/Forms/Material.scss";

@inject("authStore")
@observer
class Signup extends React.Component {

  state = {
    formRegister: {
      passwordConfirm: "",
      postcode: "",
      controlCode: ""
    },
  }

  componentDidMount() {
    document.title = 'SEAM - Signup';
  }

  componentWillUnmount() {
    this.props.authStore.reset();
  };

  handleUsernameChange = e => {
    this.validateOnChange(e);
    this.props.authStore.setUsername(e.target.value)
  };

  handleEmailChange = e => {
    this.validateOnChange(e);
    this.props.authStore.setEmail(e.target.value);
  };

  handlePasswordChange = e => {
    this.validateOnChange(e);
    this.props.authStore.setPassword(e.target.value);
  };

  handleLastnameChange = e => {
    this.validateOnChange(e);
    this.props.authStore.setLastname(e.target.value);
  };

  handlePhoneChange = e => {
    this.validateOnChange(e);
    this.props.authStore.setPhone(e.target.value);
  };

  handleFirstnameChange = e => {
    this.validateOnChange(e);
    this.props.authStore.setFirstname(e.target.value);
  };

  handleFieldChange = e => {
    this.validateOnChange(e);
  };

  onClosingControlCodeCard = () => {
    this.setState({
      formRegister: {
        ...this.state.formRegister,
        postcode: "",
        controlCode: ""
      }
    });
  }

  validateOnChange = event => {
    const input = event.target;
    const form = input.form;
    const value = input.type === "checkbox" ? input.checked : input.value;

    const result = FormValidator.validate(input);

    this.setState({
      [form.name]: {
        ...this.state[form.name],
        [input.name]: value,
        errors: {
          ...this.state[form.name].errors,
          [input.name]: result
        }
      }
    });
  };

  onSubmit = e => {
    const form = e.target;
    const inputs = [...form.elements].filter(i =>
      ["INPUT", "SELECT"].includes(i.nodeName)
    );

    const { errors, hasError } = FormValidator.bulkValidate(inputs);

    this.setState({
      [form.name]: {
        ...this.state[form.name],
        errors,
        apiErrors: {}
      }
    });

    e.preventDefault();

    if (!hasError) {
      const { postcode, controlCode } = this.state.formRegister;
      this.props.authStore.register(postcode, controlCode)
        .then(() => this.props.history.replace('/'))
        .catch(error => {
          const formErrors = error.data && error.data.arguments;
          if (formErrors && Object.keys(formErrors).length) {
            this.setState({
              formRegister: {
                ...this.state.formRegister,
                apiErrors: formErrors
              }
            });
          }
        });
    }
  };

  /* Simplify error check */
  hasError = (formName, inputName, method) => {
    return (
      this.state[formName] &&
      this.state[formName].errors &&
      this.state[formName].errors[inputName] &&
      this.state[formName].errors[inputName][method]
    );
  };

  hasApiError = (formName, inputName) => {
    return (
      this.state[formName] &&
      this.state[formName].apiErrors &&
      this.state[formName].apiErrors[inputName] &&
      this.state[formName].apiErrors[inputName].length > 0
    );
  };

  getApiErrors = (formName, inputName) => {
    if (!this.hasApiError(formName, inputName)) {
      return '';
    }
    return this.state[formName].apiErrors[inputName].join(', ');
  };

  render() {
    const { values, errors, inProgress } = this.props.authStore;

    return (
      <div className="container-full">
        <div className="container container-xs col-12 col-sm-12 col-md-8 col-xl-4 col-lg-6">
          <form className="cardbox b0 form-validate"
            action=""
            name="formRegister"
            onSubmit={this.onSubmit}
          >
            <div className="cardbox-offset pb0">
              <div className="cardbox-offset-item text-right">
                <div
                  className="btn btn-success btn-circle btn-lg invisible"
                  id="form-ok"
                >
                  <em className="ion-checkmark-round" />
                </div>
              </div>
            </div>
            <div className="cardbox-heading pb-0">
              <div className="text-center">
                <img className="img-fluid" src="img/logo.svg" alt="Logo" style={{height: '150px'}}/>
              </div>
              <div className="mt-4 cardbox-title text-center">
                Create account
              </div>
            </div>
            <div className="cardbox-body">

              <ListErrors errors={errors} />

              <div className="mda-form-group float-label mda-input-group">
                <div className="mda-form-control">
                  <Input type="text"
                    name="username"
                    invalid={
                      this.hasError(
                        "formRegister",
                        "username",
                        "required"
                      ) || this.hasApiError(
                        "formRegister",
                        "username",
                      )
                    }
                    onChange={this.handleUsernameChange}
                    data-validate='["required"]'
                    value={values.username}
                  />
                  { this.hasError('formRegister', 'username', 'required') && <span className="invalid-feedback">Field is required</span> }
                  { this.hasApiError('formRegister', 'username') && <span className="invalid-feedback"> { this.getApiErrors('formRegister', 'username') }</span> }
                  <div className="mda-form-control-line" />
                  <label>Username</label>
                </div>
                <span className="mda-input-group-addon">
                  <em className="ion-ios-person-outline icon-lg" />
                </span>
              </div>
              <div className="mda-form-group float-label mda-input-group">
                <div className="mda-form-control">
                  <Input type="text"
                    name="email"
                    invalid={
                      this.hasError(
                        "formRegister",
                        "email",
                        "required"
                      ) ||
                      this.hasError(
                        "formRegister",
                        "email",
                        "email"
                      ) || this.hasApiError(
                        "formRegister",
                        "email",
                      )
                    }
                    onChange={this.handleEmailChange}
                    data-validate='["required", "email"]'
                    value={values.email}
                  />
                  { this.hasError('formRegister', 'email', 'required') && <span className="invalid-feedback">Field is required</span> }
                  { !this.hasError('formRegister', 'email', 'required') && this.hasError('formRegister', 'email', 'email') && <span className="invalid-feedback">Field must be valid email</span> }
                  { this.hasApiError('formRegister', 'email') && <span className="invalid-feedback"> { this.getApiErrors('formRegister', 'email') }</span> }
                  <div className="mda-form-control-line" />
                  <label>Email</label>
                </div>
                <span className="mda-input-group-addon">
                  <em className="ion-ios-email-outline icon-lg" />
                </span>
              </div>
              <div className="mda-form-group float-label mda-input-group">
                <div className="mda-form-control">
                  <Input type="password"
                    name="password"
                    id="formregister-password"
                    invalid={this.hasError(
                      "formRegister",
                      "password",
                      "required"
                    )}
                    onChange={this.handlePasswordChange}
                    data-validate='["required"]'
                    value={values.password}
                  />
                  <span className="invalid-feedback">Field is required</span>
                  <div className="mda-form-control-line" />
                  <label>Password</label>
                </div>
                <span className="mda-input-group-addon">
                  <em className="ion-ios-locked-outline icon-lg" />
                </span>
              </div>
              <div className="mda-form-group float-label mda-input-group">
                <div className="mda-form-control">
                  <Input type="password"
                    name="passwordConfirm"
                    invalid={this.hasError(
                      "formRegister",
                      "passwordConfirm",
                      "equalto"
                    )}
                    onChange={this.handleFieldChange}
                    data-validate='["equalto"]'
                    value={this.state.formRegister.passwordConfirm}
                    data-param="formregister-password"
                  />
                  <span className="invalid-feedback">Passwords are not equal</span>
                  <div className="mda-form-control-line" />
                  <label>Confirm Password</label>
                </div>
                <span className="mda-input-group-addon">
                  <em className="ion-ios-locked-outline icon-lg" />
                </span>
              </div>
              <div className="mda-form-group float-label mda-input-group">
                <div className="mda-form-control">
                  <Input type="text"
                    name="firstname"
                    invalid={
                      this.hasError(
                        "formRegister",
                        "firstname",
                        "required"
                      )
                    }
                    onChange={this.handleFirstnameChange}
                    data-validate='["required"]'
                    value={values.firstname}
                  />
                  <span className="invalid-feedback">Field is required</span>
                  <div className="mda-form-control-line" />
                  <label>Firstname</label>
                </div>
                <span className="mda-input-group-addon">
                  <em className="ion-ios-information-outline icon-lg" />
                </span>
              </div>
              <div className="mda-form-group float-label mda-input-group">
                <div className="mda-form-control">
                  <Input type="text"
                    name="lastname"
                    invalid={
                      this.hasError(
                        "formRegister",
                        "lastname",
                        "required"
                      )
                    }
                    onChange={this.handleLastnameChange}
                    data-validate='["required"]'
                    value={values.lastname}
                  />
                  <span className="invalid-feedback">Field is required</span>
                  <div className="mda-form-control-line" />
                  <label>Lastname</label>
                </div>
                <span className="mda-input-group-addon">
                  <em className="ion-ios-information-outline icon-lg" />
                </span>
              </div>
              <div className="mda-form-group float-label mda-input-group">
                <div className="mda-form-control">
                  <Input type="text"
                    name="phone"
                    invalid={
                      this.hasError(
                        "formRegister",
                        "phone",
                        "required"
                      )
                    }
                    onChange={this.handlePhoneChange}
                    data-validate='["required"]'
                    value={values.phone}
                  />
                  <span className="invalid-feedback">Field is required</span>
                  <div className="mda-form-control-line" />
                  <label>Phone</label>
                </div>
                <span className="mda-input-group-addon">
                  <em className="ion-ios-telephone-outline icon-lg" />
                </span>
              </div>
              <Button color="secondary" id="linkSeam" className="mb-3">
                I have App Control Code and I would like to link my SEAM now
              </Button>
              <UncontrolledCollapse toggler="#linkSeam" onExiting={this.onClosingControlCodeCard}>
                <div className="mda-form-group float-label mda-input-group">
                  <div className="mda-form-control">
                    <Input type="text"
                      id="formRegister-postcode"
                      name="postcode"
                      invalid={
                        this.hasError(
                          "formRegister",
                          "postcode",
                          "requiredIfAnyNotEmpty"
                        ) || this.hasApiError(
                          "formRegister",
                          "postcode",
                        )
                      }
                      onChange={this.handleFieldChange}
                      data-validate='["requiredIfAnyNotEmpty"]'
                      data-param='["formRegister-controlCode"]'
                      value={this.state.formRegister.postcode}
                    />
                    { this.hasError('formRegister', 'postcode', 'requiredIfAnyNotEmpty') && <span className="invalid-feedback">Field is required</span> }
                    { this.hasApiError('formRegister', 'postcode') && <span className="invalid-feedback"> { this.getApiErrors('formRegister', 'postcode') }</span> }
                    <div className="mda-form-control-line" />
                    <label>Postcode</label>
                  </div>
                  <span className="mda-input-group-addon">
                    <em className="ion-ios-telephone-outline icon-lg" />
                  </span>
                </div>
                <div className="mda-form-group float-label mda-input-group">
                  <div className="mda-form-control">
                    <Input type="text"
                      id="formRegister-controlCode"
                      name="controlCode"
                      invalid={
                        this.hasError(
                          "formRegister",
                          "controlCode",
                          "requiredIfAnyNotEmpty"
                        ) || this.hasApiError(
                          "formRegister",
                          "control_code",
                        )
                      }
                      onChange={this.handleFieldChange}
                      data-validate='["requiredIfAnyNotEmpty"]'
                      data-param='["formRegister-postcode"]'
                      value={this.state.formRegister.controlCode}
                    />
                    { this.hasError('formRegister', 'controlCode', 'requiredIfAnyNotEmpty') && <span className="invalid-feedback">Field is required</span> }
                    { this.hasApiError('formRegister', 'control_code') && <span className="invalid-feedback"> { this.getApiErrors('formRegister', 'control_code') }</span> }
                    <div className="mda-form-control-line" />
                    <label>Control Code</label>
                  </div>
                  <span className="mda-input-group-addon">
                    <em className="ion-ios-telephone-outline icon-lg" />
                  </span>
                </div>
              </UncontrolledCollapse>
            </div>
            <button className="btn btn-primary btn-flat"
                    type="submit"
                    disabled={inProgress}
            >
              Create
            </button>
            <div className="cardbox-body bg-gray-lighter text-center text-sm">
              <span className="spr">
                By registering I accept the
              </span>
              <a className="spr" href="http://seam.solar/terms" target="_blank">
                Terms of Service
              </a>
              <span className="spr">and</span>
              <a className="spr" href="https://seam.solar/privacy-policy" target="_blank">
                Privacy
              </a>
            </div>
          </form>
          <div className="text-center mb">
            <Link className="text-black" to="/login">
              I am already a member
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

Signup.contextTypes = {
  router: PropTypes.object
};

export default Signup;
