import React from 'react';
import { ToastContainer } from 'react-toastify';
import { debounce } from 'debounce'
import classnames from 'classnames'; 
import './Core.scss';
import './LayoutVariants.scss';
import Header from '../Header/Header';
import "react-toastify/dist/ReactToastify.css";

class Core extends React.Component {
  containerRef = React.createRef();
  state = {
    scrolled: false,
    isMobileView: false
  }

  componentDidMount() {
    this.listenToScroll;
    //this.containerRef.current.addEventListener('scroll', this.listenToScroll);
    window.addEventListener('resize', this.listenResize);
    document.addEventListener('touchmove', function (event) {
      if (event.scale !== 1) {
        event.preventDefault();
      }
    }, false);
  }

  componentWillUnmount() {
    this.containerRef.current.removeEventListener('scroll', this.listenToScroll);
    window.removeEventListener('resize', this.listenResize);
    document.removeEventListener('touchmove', this.listenResize);
  }

  listenToScroll = () => {
    const scrolled = this.containerRef.current.scrollTop;
  
    const minimized = scrolled > 50 && this.state.isMobileView;
    if (minimized !== this.state.scrolled) {
      this.setState({
        scrolled: minimized,
      })
    }
  }

  listenResize = () => {
    const isMobileView = window.innerWidth < 768;
    if (isMobileView !== this.state.isMobileView) {
      this.setState({
        isMobileView: isMobileView
      });
      if (isMobileView) {
        this.containerRef.current.addEventListener('scroll', this.listenToScroll);
      } else {
        this.containerRef.current.removeEventListener('scroll', this.listenToScroll);
      }
    }
    this.listenToScroll();
  }

  render() {
    const { scrolled } = this.state;
    return (
      <div className={classnames({scrolled: scrolled}) + " layout-container"}>
        <Header />
        <div className="sidebar-layout-obfuscator"></div>
        <main className="main-container" ref={this.containerRef}>
          {this.props.children}
          <footer>
            <div>
              &copy; {(new Date()).getFullYear()} <a href="https://seam.solar/" target="_blank">SEAM</a> by <a href="https://enertek.com.au/" target="_blank">Enertek</a>
            </div>
          </footer>
        </main>
        <ToastContainer />
        {/* Search template */}
        {/* <HeaderSearch/> */}
        {/* Settings template */}
        {/* <Settings/> */}
      </div>
    );
  }
}

export default Core;
