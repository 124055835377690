import { observable, action } from 'mobx';
import api from 'api';

class PowerDiagramStore {
  @observable loading = false;
  @observable data = null;

  @action getReport(premiseId, date) {
    this.loading = true;
    return api.Report.powerDiagram(premiseId, date)
      .then(({ data }) => {
        this.data = data;
      })
      .finally(() => {
        this.loading = false;
      })
    ;
  }

  @action reset() {
    this.loading = false;
    this.data = null;
  }
}

export default new PowerDiagramStore();