// import 'core-js/es6/string';
// import 'core-js/es6/array';
// import 'core-js/es6/map';
// import 'core-js/es6/set';
// import 'core-js/es6/object';
// import 'core-js/es6/promise';
// import 'core-js/es7/object';
// import 'core-js/es7/array';
// import 'raf/polyfill';

import React from 'react';
import ReactDOM from 'react-dom';
import promiseFinally from 'promise.prototype.finally';
import { configure } from "mobx";
import { Provider } from 'mobx-react';
// import createBrowserHistory from 'history/createBrowserHistory';
// import { RouterStore, syncHistoryWithStore } from 'mobx-react-router';
import App from './App';
import stores from './stores/index';
// import './i18n';

window._____APP_STATE_____ = stores;

promiseFinally.shim();

//configure({ enforceActions: "observed" });

// const browserHistory = createBrowserHistory();
// const routingStore = new RouterStore();
// const history = syncHistoryWithStore(browserHistory, routingStore);

ReactDOM.render(
  <Provider {...stores}>
    <App />
  </Provider>
  ,
  document.getElementById('app')
);
