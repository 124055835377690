import http from './http';

const Report = {
  powerDiagram: (premiseId, date) =>
    http.get('/v1/report/power-diagram', {
      params: {
        premise_id: premiseId,
        date
      }
    }),
  energyConsumption: (premiseId, date) =>
    http.get('/v1/report/energy-consumption', {
      params: {
        premise_id: premiseId,
        date
      }
    }),
  generation: (premiseId, date) =>
    http.get('/v1/report/generation', {
      params: {
        premise_id: premiseId,
        date
      }
    }),
  energyFlow: (premiseId, rtd) =>
    http.get('/v1/report/get-energy-flow', {
      params: {
        premise_id: premiseId,
        realtime: rtd ? 1 : 0
      }
    }),
  dashboard: (premiseId, date) =>
    http.get('/v1/report/dashboard', {
      params: {
        premise_id: premiseId,
        date
      }
    }),
  dashboardAvailableDates: (premiseId, date) =>
    http.get('/v1/report/dashboard-available-dates', {
      params: {
        premise_id: premiseId,
        date
      }
    }),
  dashboardYearly: (premiseId, year) =>
    http.get('/v1/report/get-yearly-report', {
      params: {
        premise_id: premiseId,
        year
      }
    }),
  energy: (premiseId, date) =>
    http.get('/v1/report/energy-report', {
      params: {
        premise_id: premiseId,
        date
      }
    }),
};

export default Report;
