import React from 'react';

const Layout = props => (
  <div className="layout-container">
    <div className="page-container bg-auth">
      {props.children}
    </div>
  </div>
)

export default Layout;
