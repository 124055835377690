import React from "react";
import { Link, withRouter, Redirect } from "react-router-dom";
import { inject, observer } from 'mobx-react';
import { Input } from "reactstrap";
import PropTypes from "prop-types";
import ListErrors from 'components/ListErrors';
import FormValidator from "components/Forms/Validator.js";
import "components/Forms/Material.scss";

@inject("authStore")
@observer
class Recovery extends React.Component {

  state = {
    inProgressRecovery: false,
    recoveryForm: {},
    email: null
  }

  componentDidMount() {
    document.title = 'SEAM - Recovery Password';
  }

  componentWillUnmount() {
    this.props.authStore.reset();
  };

  handleUsernameChange = e => {
    this.validateOnChange(e);
    this.props.authStore.setUsername(e.target.value)
  };

  handleRecoveryCodeChange = e => {
    this.validateOnChange(e);
    this.props.authStore.setRecoveryCode(e.target.value);
  };

  validateOnChange = event => {
    const input = event.target;
    const form = input.form;

    const result = FormValidator.validate(input);

    this.setState({
      [form.name]: {
        ...this.state[form.name],
        errors: {
          ...this.state[form.name].errors,
          [input.name]: result
        }
      }
    });
  };

  onSubmit = e => {
    const form = e.target;
    const inputs = [...form.elements].filter(i =>
      ["INPUT", "SELECT"].includes(i.nodeName)
    );

    const { errors, hasError } = FormValidator.bulkValidate(inputs);

    this.setState({
      [form.name]: {
        ...this.state[form.name],
        errors,
        apiErrors: {}
      }
    });

    e.preventDefault();

    if (!hasError) {
      if (this.props.authStore.values.password) {
        this.props.authStore.login()
          .then(() => this.props.history.replace('/'));
        return;
      }
      if (this.state.inProgressRecovery) {
        this.props.authStore.recoveryValidateCode()
          .catch(error => {
            const formErrors = error.data && error.data.arguments;
            if (formErrors && Object.keys(formErrors).length) {
              this.setState({
                recoveryForm: {
                  ...this.state.recoveryForm,
                  apiErrors: formErrors
                }
              });
            }
          });
        return;
      }
      this.props.authStore.recoverySendCode()
        .then(({ data }) => {
          this.setState({
            inProgressRecovery: true,
            email: data.email
          });
        })
        .catch(error => {
          const formErrors = error.data && error.data.arguments;
          if (formErrors && Object.keys(formErrors).length) {
            this.setState({
              recoveryForm: {
                ...this.state.recoveryForm,
                apiErrors: formErrors
              }
            });
          }
        });
    }
  };

  /* Simplify error check */
  hasError = (formName, inputName, method) => {
    return (
      this.state[formName] &&
      this.state[formName].errors &&
      this.state[formName].errors[inputName] &&
      this.state[formName].errors[inputName][method]
    );
  };

  hasApiError = (formName, inputName) => {
    return (
      this.state[formName] &&
      this.state[formName].apiErrors &&
      this.state[formName].apiErrors[inputName] &&
      this.state[formName].apiErrors[inputName].length > 0
    );
  };

  getApiErrors = (formName, inputName) => {
    if (!this.hasApiError(formName, inputName)) {
      return '';
    }
    return this.state[formName].apiErrors[inputName].join(', ');
  };

  render() {
    const { values, errors, inProgress } = this.props.authStore;
    const { email } = this.state;

    return (
      <div className="container-full">
        <div className="container container-xs col-12 col-sm-12 col-md-8 col-xl-4 col-lg-6">
          <form className="cardbox b0 form-validate"
            action=""
            name="recoveryForm"
            onSubmit={this.onSubmit}
          >
            <div className="cardbox-offset pb0">
              <div className="cardbox-offset-item text-right">
                <div className="btn btn-success btn-circle btn-lg invisible"
                     id="form-ok"
                >
                  <em className="ion-checkmark-round" />
                </div>
              </div>
            </div>
            {
              !values.password &&
              <div>
                <div className="cardbox-heading">
                  <p className="text-center text-inherit">Fill with your username to receive instructions on how to reset your password.</p>
                </div>
                <div className="cardbox-offset pb0">
                  <div className="cardbox-offset-item text-right ng-fadeInLeftShort">
                    <button className="btn btn-success btn-circle btn-lg" type="submit">
                      <em className="ion-ios-arrow-forward"></em>
                    </button>
                  </div>
                </div>

                <div className="cardbox-body">

                  <ListErrors errors={errors} />

                  <div className="mda-form-group float-label mda-input-group">
                    <div className="mda-form-control">
                      <Input type="text"
                        name="username"
                        invalid={
                          this.hasError(
                            "recoveryForm",
                            "username",
                            "required"
                          ) || this.hasApiError(
                            "recoveryForm",
                            "username",
                          )
                        }
                        onChange={this.handleUsernameChange}
                        data-validate='["required"]'
                        value={values.username}
                        disabled={this.state.inProgressRecovery}
                      />
                      { this.hasError('recoveryForm', 'username', 'required') && <span className="invalid-feedback">Field is required</span> }
                      { this.hasApiError('recoveryForm', 'username') && <span className="invalid-feedback"> { this.getApiErrors('recoveryForm', 'username') }</span> }
                      <div className="mda-form-control-line" />
                      <label>Username</label>
                    </div>
                    <span className="mda-input-group-addon">
                      <em className="ion-ios-person-outline icon-lg" />
                    </span>
                  </div>
                  {
                    email &&
                    <p>
                      Please check your email ({email}) as we sent a verification code for you to reset the password
                    </p>
                  }
                  {
                    this.state.inProgressRecovery &&
                    <div className="mda-form-group float-label mda-input-group">
                      <div className="mda-form-control">
                        <Input type="text"
                          name="code"
                          invalid={
                            this.hasError(
                              "recoveryForm",
                              "code",
                              "required"
                            ) || this.hasApiError(
                              "recoveryForm",
                              "code",
                            )
                          }
                          onChange={this.handleRecoveryCodeChange}
                          data-validate='["required"]'
                          value={values.recoveryCode}
                        />
                        { this.hasError('recoveryForm', 'code', 'required') && <span className="invalid-feedback">Field is required</span> }
                        { this.hasApiError('recoveryForm', 'code') && <span className="invalid-feedback"> { this.getApiErrors('recoveryForm', 'code') }</span> }
                        <div className="mda-form-control-line" />
                        <label>Verification code</label>
                      </div>
                      <span className="mda-input-group-addon">
                        <em className="ion-ios-email-outline icon-lg" />
                      </span>
                    </div>
                  }
                </div>
              </div>
            }
            {
              values.password &&
              <div className="cardbox-body">
                <div className="mt-5 text-center">
                  <p>Your new password is: <strong>{values.password}</strong></p>
                  <p>We have also sent you a copy of this password to the registered email address</p>
                  <p>Click the below button to login</p>
                </div>
              </div>
            }
            {
              values.password &&
              <button className="btn btn-primary btn-flat"
                type="submit"
                disabled={inProgress}
              >
                Authenticate
              </button>
            }
          </form>
          <div className="text-center mb">
            <Link className="text-black" to="/login">
              Back
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

Recovery.contextTypes = {
  router: PropTypes.object
};

export default Recovery;
