import { observable, action, computed, toJS } from 'mobx';
import pubsub from 'pubsub-js';
import api from 'api';
import { PremiseNotFound } from 'exceptions';

class PremiseStore {
  @observable loaded = false;
  @observable loading = false;
  @observable _list = [];

  set list(list) {
    this.loaded = true;
    this._list = list;
    pubsub.publish("Premise.setList", toJS(list));
  }

  @computed get list() {
    if (!this.loaded) {
      return this.reloadList().then(() => toJS(this._list));
    }
    return toJS(this._list);
  }

  @computed get count() {
    return this.list.length;
  }

  @action reloadList() {
    this.loading = true;
    return api.Premise.current()
      .then(({ data }) => {
        this.list = data;
      })
      .finally(() => {
        this.loading = false;
      })
    ;
  }

  @action remindControlCode(lastname, postcode) {
    this.loading = true;
    this.errors = undefined;
    return api.Premise.remindControlCode(lastname, postcode)
      .catch((error) => {
        if (error.data && error.data.message) {
          const message = error.data.message === "Premise not found" ? "Unfortunately we could not locate SEAM installation based on the information provided. If you believe this is an error please get in touch with your SEAM retailer" : error.data.message
          this.errors = [message];
        }
        throw err;
      })
      .finally(() => { this.loading = false; });
  }


  @action link(lastname, postcode, controlCode) {
    this.loading = true;
    this.errors = undefined;
    return api.Premise.link(lastname, postcode, controlCode)
      .then(({data}) => {
        if (!data.success) {
          this.errors = ['Erorr linkin premise'];
        }
        return data;
      })
      .catch((error) => {
        if (error.data && error.data.message) {
          const message = error.data.message === "Premise not found" ? "Unfortunately we could not locate SEAM installation based on the information provided. If you believe this is an error please get in touch with your SEAM retailer" : error.data.message
          this.errors = [message];
        }
        throw error;
      })
      .finally(() => { this.loading = false; });
  }

  async getActivePremise(id) {
    const list = await this.list;
    const premise = list.find(e => e.id === parseInt(id));
    if (undefined === premise) {
      throw new PremiseNotFound();
    }
    pubsub.publish("Premise.setActivePremise", premise);
    return premise;
  }

  weatherHourly(premiseId, date) {
    return api.Premise.weatherHourly(premiseId, date)
      .then(({ data }) => data);
  }
}

export default new PremiseStore();
