import { observable, action } from 'mobx';
import api from 'api';

class ReportStore {
  @observable energyFlowLoading = false;
  @observable energyFlowClear = true;
  @observable energyFlow = null;
  @observable powerReportLoading = false;
  @observable powerReport = null;
  @observable errors = undefined;
  energyFlowTimerId = null;

  @action getPowerReport(premiseId, date) {
    this.powerReportLoading = true;
    this.errors = undefined;
    return api.Report.powerDiagram(premiseId, date)
      .then(({ data }) => {
        this.powerReport = data;
      })
      .finally(() => { this.powerReportLoading = false; });
  }

  @action getEnergyFlow(premiseId, rtd) {
    return api.Report.energyFlow(premiseId, rtd)
      .then(({ data }) => {
        this.energyFlow = data;
      });
  }

  @action getEnergyFlowInterval(premiseId) {
    this.energyFlowLoading = true;
    this.energyFlowClear = false;
    clearTimeout(this.energyFlowTimerId);
    this.energyFlowTimerId = setTimeout(() => {
      this.getEnergyFlow(premiseId, true)
        .then(() => {
          if (!this.energyFlowClear) {
            this.getEnergyFlowInterval(premiseId)
          }
        })
        .finally(() => { this.energyFlowLoading = false; });
    }, 5000)
  }

  @action clearEnergyFlowInterval() {
    clearTimeout(this.energyFlowTimerId);
    this.energyFlowClear = true;
  }

  @action resetEnergyFlow() {
    clearTimeout(this.energyFlowTimerId);
    this.energyFlowLoading = false;
    this.energyFlowClear = true;
    this.energyFlow = null;
  }
}

export default new ReportStore();