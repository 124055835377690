import http from './http';

const PremiseSettings = {
  getEnergyRate: (premiseId) => 
    http.get('/v1/premise/settings/get-energy-rate', {params: {premise_id: premiseId}}),
  setEnergyRate: (premiseId, rate) => 
    http.post('/v1/premise/settings/set-energy-rate', {
      premise_id: premiseId,
      buy_tariff_type: rate.buyTariffType,
      buy_tariff_value_1: rate.buyTariffValue1,
      buy_tariff_value_2: rate.buyTariffValue2,
      buy_tariff_value_3: rate.buyTariffValue3,
      weekdays_peak_from: rate.weekdaysPeakFrom,
      weekdays_peak_to: rate.weekdaysPeakTo,
      weekends_peak_from: rate.weekendsPeakFrom,
      weekends_peak_to: rate.weekendsPeakTo,
      weekdays_off_peak_from: rate.weekdaysOffPeakFrom,
      weekdays_off_peak_to: rate.weekdaysOffPeakTo,
      weekends_off_peak_from: rate.weekendsOffPeakFrom,
      weekends_off_peak_to: rate.weekendsOffPeakTo,
      weekdays_shoulder_from: rate.weekdaysShoulderFrom,
      weekdays_shoulder_to: rate.weekdaysShoulderTo,
      weekends_shoulder_from: rate.weekendsShoulderFrom,
      weekends_shoulder_to: rate.weekendsShoulderTo,
      feed_in_tariff: rate.feedInTariff
    }),
  getNotifications: (premiseId) => 
    http.get('/v1/premise/settings/get-notifications', {params: {premise_id: premiseId}}),
  setNotifications: (premiseId, dailyEmails, weeklyEmails) => 
    http.post('/v1/premise/settings/set-notifications', {
      premise_id: premiseId,
      send_daily_emails: dailyEmails,
      send_weekly_emails: weeklyEmails
    }),
};

export default PremiseSettings;
