import authStore from './AuthStore';
import premiseStore from './PremiseStore';
import premiseSettingsStore from './premise/SettingsStore';
import profileStore from './ProfileStore';
import reportStore from './ReportStore';
import reportDashboardStore from './reports/DashboardStore';
import reportEnergyConsumptionStore from './reports/EnergyConsumptionStore';
import reportEnergyStore from './reports/EnergyStore';
import reportGenerationStore from './reports/GenerationStore';
import reportPowerDiagramStore from './reports/PowerDiagramStore';

export default {
  authStore,
  profileStore,
  premiseStore,
  premiseSettingsStore,
  reportStore,
  reportDashboardStore,
  reportEnergyConsumptionStore,
  reportEnergyStore,
  reportGenerationStore,
  reportPowerDiagramStore
}
