import http from './http';

const Auth = {
  current: () => 
    http.get('/v1/user/current'),
  login: (username, password) =>
    http.post('/v1/user/authorise-by-password', { username, password }),
  logout: () =>
    http.get('/v1/user/logout'),
  register: (username, password, email, phone, lastname, firstname, postcode, controlCode) =>
    http.post('/v1/user/register-with-username-and-password', { 
      username,
      password,
      email,
      phone,
      last_name: lastname,
      first_name: firstname,
      postcode,
      control_code: controlCode
    }),
  updateToken: (refreshToken) =>
    http.post('/v1/user/update-access-token', { refresh_token: refreshToken }),
  recoverySendCode: (username) =>
    http.post('/v1/user/forgot-password-send-code', { username }),
  recoveryValidateCode: (username, code) =>
    http.post('/v1/user/forgot-password-validate-code', { username, code }),
};

export default Auth;
