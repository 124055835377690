import http from './http';

const Premise = {
  current: () =>
    http.get('/v1/premise/current'),
  remindControlCode: (lastname, postcode) => 
    http.post('/v1/premise/remind-control-code', {last_name: lastname, postcode}),
  link: (lastname, postcode, controlCode) => 
    http.post('/v1/premise/link', {last_name: lastname, postcode, control_code: controlCode}),
  energyFlow: (premiseId) =>
    http.get('/v1/report/get-energy-flow', {
      params: {
        premise_id: premiseId
      }
    }),
  getPowerReport: (premiseId, date) =>
    http.get('/v1/report/power-diagram', {params: {premise_id: premiseId, date}}),
  weatherHourly: (premiseId, date) =>
    http.get('/v1/premise/weather/hourly', {params: {premise_id: premiseId, date}}),
};

export default Premise;
