import http from './http';

const Profile = {
    getMyProfile: () =>
        http.get('/v1/user/current'),
    saveMyProfile: ({username , first_name, last_name, phone, email}) =>
        http.post('/v1/user/profile', {username , first_name, last_name, phone, email}),
    updatePassword: (currentPassword, newPassword) =>
        http.post('/v1/user/password', {oldPassword: currentPassword, newPassword}),
};

export default Profile;
