import { toast } from "react-toastify";

const notify = (type, message) => {
  toast(message, {
    type: type,
    position: "top-center"
  });
};

export default {
  success: message => notify("success", message),
  error: message => notify("error", message),
  info: message => notify("info", message),
  warning: message => notify("warning", message),
};
