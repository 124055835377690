import Login from './Login';
import Layout from './Layout';
import Recovery from './Recovery';
import Signup from './Signup';

export default {
  Login,
  Layout,
  Recovery,
  Signup,
}
