import { observable, action, toJS } from 'mobx';
import api from 'api';

class ProfileStore {
  @observable profileData = {};
  @observable inProgress = false;
  @observable updatingPassword = false;
  @observable errors = undefined;

  @action getMyProfile = () => {
    this.inProgress = true;
    this.errors = undefined;
    return api.Profile.getMyProfile()
      .then(({ data }) => {
        this.profileData = toJS(data);
      })
      .catch(error => {
        this.errors = [error.data && error.data.message];
        throw error;
      })
      .finally(() => { this.inProgress = false; });
  }

  @action saveMyProfile = (profileData) => {
    this.inProgress = true;
    this.errors = undefined;
    return api.Profile.saveMyProfile(profileData)
      .catch(error => {
        this.errors = [error.data && error.data.message];
        throw error;
      })
      .finally(() => { this.inProgress = false; });
  }

  @action updatePassword(currentPassword, newPassword) {
    this.errors = undefined;
    return api.Profile.updatePassword(currentPassword, newPassword)
      .catch(error => {
        this.errors = [error.data && error.data.message];
        throw error;
      })
  }
}

export default new ProfileStore();