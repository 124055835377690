import React, { Suspense, lazy } from 'react';
import { withRouter, Switch, Route, Redirect } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import pubsub from 'pubsub-js';
import PageLoader from './components/Common/PageLoader';
import Core from './components/Core/Core';
import User from './app/User';


const waitFor = Tag => props => <Tag {...props} />;

const Dashboard = lazy(() => import('./app/Dashboard/Dashboard'));
const SolarViewAdd = lazy(() => import('./app/SolarView/Add'));
const Profile = lazy(() => import('./app/Profile/Profile'));
const PremiseDashboard = lazy(() => import('./app/Premise/Dashboard'));
const PremisePowerDiagramReport = lazy(() => import('./app/Premise/reports/PowerDiagram'));
const PremiseHeatmapReport = lazy(() => import('./app/Premise/reports/Heatmap'));
const PremiseEnergyReport = lazy(() => import('./app/Premise/reports/Energy'));
const PremiseSettingsEnergyRate = lazy(() => import('./app/Premise/settings/EnergyRate'));
const PremiseSettingsNotifications = lazy(() => import('./app/Premise/settings/Notifications'));
const Messages = lazy(() => import('./app/Messages'));

const Error404 = lazy(() => import('./components/Error404'));

const listofPages = [
  '/login',
  '/signup',
  '/recovery',
];

let layoutTitle = pubsub.subscribe('Layout.title', (ev, title) => {
  if (title) {
    document.title = 'SEAM - ' + title;
  } else {
    document.title = 'SEAM';
  }
});

const Routes = ({ location, authStore, history, title }) => {
  const currentKey = location.pathname.split('/')[1] || '/';
  const timeout = { enter: 500, exit: 500 };
  const animationName = 'rag-fadeIn';
  const { currentUser } = authStore;
  pubsub.publish("Layout.title", null);

  if (listofPages.indexOf(location.pathname) > -1) {
    if (currentUser) {
      return (<Redirect to="/" />);
    }

    return (
      <Suspense fallback={<PageLoader />}>
        <User.Layout>
          <Switch location={location}>
            <Route path="/login" component={waitFor(User.Login)} />
            <Route path="/signup" component={waitFor(User.Signup)} />
            <Route path="/recovery" component={waitFor(User.Recovery)} />
          </Switch>
        </User.Layout>
      </Suspense>
    )
  }

  if (!currentUser) {
    return (
      <Redirect to={{ pathname: "/login", state: { from: location } }} />
    );
  }

  if ('/logout' === location.pathname) {
    authStore.logout()
      .then(() => window.location.href = '/login');
    return null;
  }

  return (
    <Core>
      <TransitionGroup>
        <CSSTransition key={currentKey} timeout={timeout} classNames={animationName} exit={false}>
          <div>
            <Suspense fallback={<PageLoader />}>
              <Switch location={location}>
                <Route exact path="/" component={waitFor(Dashboard)} />
                <Route exact path="/dashboard" component={waitFor(Dashboard)} />
                <Route exact path="/solar-view/:id/dashboard" component={waitFor(PremiseDashboard)} />
                <Route exact path="/solar-view/:id/reports/power-diagram" component={waitFor(PremisePowerDiagramReport)} />
                <Route exact path="/solar-view/:id/reports/heatmap" component={waitFor(PremiseHeatmapReport)} />
                <Route exact path="/solar-view/:id/reports/energy" component={waitFor(PremiseEnergyReport)} />
                <Route exact path="/solar-view/:id/settings/energy-rate" component={waitFor(PremiseSettingsEnergyRate)} />
                <Route exact path="/solar-view/:id/settings/notifications" component={waitFor(PremiseSettingsNotifications)} />
                <Redirect from="/solar-view/:id/settings" to="/solar-view/:id/settings/energy-rate" />
                <Route exact path="/solar-view/add" component={waitFor(SolarViewAdd)} />
                <Route exact path="/messages" component={waitFor(Messages)} />
                <Route exact path="/profile" component={waitFor(Profile)} />
                <Route component={waitFor(Error404)} />
              </Switch>
            </Suspense>
          </div>
        </CSSTransition>
      </TransitionGroup>
    </Core>
  )
}

//export default inject("authStore")(observer(withRouter(Routes)));
export default inject("authStore")(withRouter(Routes));
