import http from './http';
import Auth from './Auth';
import Premise from './Premise';
import PremiseSettings from './PremiseSettings';
import Profile from './Profile';
import Report from './Report';

export default {
  http,
  Auth,
  Premise,
  PremiseSettings,
  Report,
  Profile
}
