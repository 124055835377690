import { observable, action } from 'mobx';
import api from 'api';

class SettingsStore {
  @observable loading = false;
  @observable saving = false;
  @observable errors;

  @action getEnergyRate(premiseId) {
    this.loading = true;
    this.errors = undefined;
    return api.PremiseSettings.getEnergyRate(premiseId)
      .then(({ data }) => {
        return data;
      })
      .catch(error => {
        this.errors = [error.data && error.data.message];
        throw error;
      })
      .finally(() => { this.loading = false; });
  }

  @action setEnergyRate(premiseId, rate) {
    this.saving = true;
    this.errors = undefined;
    return api.PremiseSettings.setEnergyRate(premiseId, rate)
      .catch(error => {
        this.errors = [error.data && error.data.message];
        throw error;
      })
      .finally(() => { this.saving = false; });
  }

  @action getNotifications(premiseId) {
    this.loading = true;
    this.errors = undefined;
    return api.PremiseSettings.getNotifications(premiseId)
      .then(({ data }) => {
        return data;
      })
      .catch(error => {
        this.errors = [error.data && error.data.message];
        throw error;
      })
      .finally(() => { this.loading = false; });
  }

  @action setNotifications(premiseId, dailyEmails, weeklyEmails) {
    this.saving = true;
    this.errors = undefined;
    return api.PremiseSettings.setNotifications(premiseId, dailyEmails ? 1 : 0, weeklyEmails ? 1 : 0)
      .catch(error => {
        this.errors = [error.data && error.data.message];
        throw error;
      })
      .finally(() => { this.saving = false; });
  }
}

export default new SettingsStore();
