import { observable, action } from 'mobx';
import api from 'api';

class DashboardStore {
  @observable loading = false;
  @observable loadingYearly = false;
  @observable data = null;
  @observable dataYearly = null;
  @observable availableDates = [];

  @action getReport(premiseId, date) {
    this.loading = true;
    return api.Report.dashboard(premiseId, date)
      .then(({ data }) => {
        this.data = data;
      })
      .finally(() => {
        this.loading = false;
      })
    ;
  }

  @action getReportYearly(premiseId, year) {
    if (this.dataYearly && this.dataYearly.date == year) {
      return;
    }
    this.loadingYearly = true;
    return api.Report.dashboardYearly(premiseId, year)
      .then(({ data }) => {
        this.dataYearly = data;
      })
      .finally(() => {
        this.loadingYearly = false;
      })
    ;
  }

  @action getAvailableDates(premiseId, date) {
    return api.Report.dashboardAvailableDates(premiseId, date)
      .then(({ data }) => {
        this.availableDates = data;
      })
    ;
  }

  @action reset() {
    this.loading = false;
    this.loadingYearly = false;
    this.data = null;
    this.dataYearly = null;
    this.availableDates = [];
  }
}

export default new DashboardStore();