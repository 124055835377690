import { observable, action, reaction } from 'mobx';
import api from 'api';

class AuthStore {
  @observable currentUser = window.localStorage.getItem('currentUser');
  @observable accessToken = window.localStorage.getItem('access-token');
  @observable refreshToken = window.localStorage.getItem('refresh-token');
  @observable inProgress = false;
  @observable errors = undefined;
  @observable formErrors = {};

  @observable values = {
    username: '',
    password: '',
    email: '',
    firstname: '',
    lastname: '',
    phone: '',
    recoveryCode: ''
  };

  constructor() {
    reaction(
      () => this.accessToken,
      token => {
        if (token) {
          window.localStorage.setItem('access-token', token);
        } else {
          window.localStorage.removeItem('access-token');
        }
      }
    );
    reaction(
      () => this.refreshToken,
      token => {
        if (token) {
          window.localStorage.setItem('refresh-token', token);
        } else {
          window.localStorage.removeItem('refresh-token');
        }
      }
    );
    reaction(
      () => this.currentUser,
      currentUser => {
        if (currentUser) {
          window.localStorage.setItem('currentUser', currentUser);
        } else {
          window.localStorage.removeItem('currentUser');
        }
      }
    );
  }

  @action setTokens(accessToken, refreshToken) {
    this.accessToken = accessToken;
    this.refreshToken = refreshToken;
  }

  @action setCurrentUser(currentUser) {
    this.currentUser = currentUser;
    if (currentUser) {
      this.setTokens(currentUser.tokens.access_token, currentUser.tokens.refresh_token);
    }
  }

  @action setUsername(username) {
    this.values.username = username;
  }

  @action setPassword(password) {
    this.values.password = password;
  }

  @action setEmail(email) {
    this.values.email = email;
  }

  @action setFirstname(firstname) {
    this.values.firstname = firstname;
  }

  @action setLastname(lastname) {
    this.values.lastname = lastname;
  }

  @action setPhone(phone) {
    this.values.phone = phone;
  }

  @action setRecoveryCode(code) {
    this.values.recoveryCode = code;
  }

  @action reset() {
    this.inProgress = false;
    this.values.username = '';
    this.values.password = '';
    this.values.email = '';
    this.values.firstname = '';
    this.values.lastname = '';
    this.values.phone = '';
    this.values.recoveryCode = '';
    this.errors = undefined;
    this.formErrors = {};
  }

  @action login() {
    this.inProgress = true;
    this.errors = undefined;
    this.formErrors = {};
    return api.Auth.login(this.values.username, this.values.password)
      .then(({ data }) => this.setCurrentUser(data))
      .catch(error => {
        if (error.response) {
          this.errors = [error.response.data && error.response.data.message];
          this.formErrors = error.response.data && error.response.data.arguments;
        } else {
          this.errors = [error.data && error.data.message];
          this.formErrors = error.data && error.data.arguments;
        }
        throw error;
      })
      .finally(() => { this.inProgress = false; });
  }

  @action register(postcode, controlCode) {
    this.inProgress = true;
    this.errors = undefined;
    this.formErrors = {};
    return api.Auth.register(this.values.username, this.values.password, this.values.email, this.values.phone, this.values.lastname, this.values.firstname, postcode, controlCode)
      .then(({ data }) => this.setCurrentUser(data))
      .catch(error => {
        if (error.response) {
          this.errors = [error.response.data && error.response.data.message];
          this.formErrors = error.response.data && error.response.data.arguments;
        } else {
          this.errors = [error.data && error.data.message];
          this.formErrors = error.data && error.data.arguments;
        }
        throw error;
      })
      .finally(() => { this.inProgress = false; });
  }

  @action recoverySendCode() {
    this.inProgress = true;
    this.errors = undefined;
    this.formErrors = {};
    return api.Auth.recoverySendCode(this.values.username)
      .catch(error => {
        this.errors = [error.data && error.data.message];
        this.formErrors = error.data && error.data.arguments;
        
        throw error;
      })
      .finally(() => { this.inProgress = false; });
  }

  @action recoveryValidateCode() {
    this.inProgress = true;
    this.errors = undefined;
    this.formErrors = {};
    return api.Auth.recoveryValidateCode(this.values.username, this.values.recoveryCode)
      .then(({ data }) => this.setPassword(data.password))
      .catch(error => {
        this.errors = [error.data && error.data.message];
        this.formErrors = error.data && error.data.arguments;
        throw error;
      })
      .finally(() => { this.inProgress = false; });
  }

  @action logout() {
    this.setTokens(undefined, undefined);
    this.setCurrentUser(undefined);
    return Promise.resolve();
  }
}

export default new AuthStore();