import React from "react";
import { Link, withRouter, Redirect } from "react-router-dom";
import { inject, observer } from 'mobx-react';
import { Input } from "reactstrap";
import PropTypes from "prop-types";
import ListErrors from 'components/ListErrors';
import FormValidator from "components/Forms/Validator.js";
import "components/Forms/Material.scss";

@inject("authStore")
@observer
class Login extends React.Component {

  state = {
    formLogin: {}
  }

  componentDidMount() {
    document.title = 'SEAM - Signin';
  }

  componentWillUnmount() {
    this.props.authStore.reset();
  };

  handleUsernameChange = e => {
    this.validateOnChange(e);
    this.props.authStore.setUsername(e.target.value)
  };

  handlePasswordChange = e => {
    this.validateOnChange(e);
    this.props.authStore.setPassword(e.target.value);
  };

  validateOnChange = event => {
    const input = event.target;
    const form = input.form;
    const result = FormValidator.validate(input);

    this.setState({
      [form.name]: {
        errors: {
          ...this.state[form.name].errors,
          [input.name]: result
        }
      }
    });
  };

  onSubmit = e => {
    const form = e.target;
    const inputs = [...form.elements].filter(i =>
      ["INPUT", "SELECT"].includes(i.nodeName)
    );

    const { errors, hasError } = FormValidator.bulkValidate(inputs);

    this.setState({
      [form.name]: {
        ...this.state[form.name],
        errors
      }
    });

    e.preventDefault();

    if (!hasError) {
      this.props.authStore.login()
        .then(() => this.props.history.replace('/'));
    }
  };

  /* Simplify error check */
  hasError = (formName, inputName, method) => {
    return (
      this.state[formName] &&
      this.state[formName].errors &&
      this.state[formName].errors[inputName] &&
      this.state[formName].errors[inputName][method]
    );
  };

  render() {
    const { values, errors, inProgress } = this.props.authStore;

    return (
      <div className="container-full">
        <div className="container container-xs col-12 col-sm-12 col-md-8 col-xl-4 col-lg-6">
          <form className="cardbox b0 form-validate"
                action=""
                name="formLogin"
                onSubmit={this.onSubmit}
          >
            <div className="cardbox-heading">
              <div className="cardbox-title text-center">
                <img className="img-fluid" src="img/logo.svg" alt="Logo" style={{height: '150px'}}/>
              </div>
            </div>
            <div className="cardbox-body">

              <ListErrors errors={errors} />

              <div className="mda-form-group float-label mda-input-group">
                <div className="mda-form-control">
                  <Input type="text"
                         name="username"
                         invalid={
                          this.hasError(
                            "formLogin",
                            "username",
                            "required"
                          )
                         }
                         onChange={this.handleUsernameChange}
                         data-validate='["required"]'
                         value={values.username}
                  />
                  <span className="invalid-feedback">Field is required</span>
                  <div className="mda-form-control-line" />
                  <label>Username</label>
                </div>
                <span className="mda-input-group-addon">
                  <em className="ion-ios-person-outline icon-lg" />
                </span>
              </div>
              <div className="mda-form-group float-label mda-input-group">
                <div className="mda-form-control">
                  <Input type="password"
                         name="password"
                         invalid={this.hasError(
                          "formLogin",
                          "password",
                          "required"
                         )}
                         onChange={this.handlePasswordChange}
                         data-validate='["required"]'
                         value={values.password}
                  />
                  <span className="invalid-feedback">Field is required</span>
                  <div className="mda-form-control-line" />
                  <label>Password</label>
                </div>
                <span className="mda-input-group-addon">
                  <em className="ion-ios-locked-outline icon-lg" />
                </span>
              </div>
            </div>
            <button className="btn btn-primary btn-flat"
                    type="submit"
                    disabled={inProgress}
            >
              Authenticate
            </button>
          </form>
          <div className="text-center mb">
            <Link className="text-black" to="/signup">
              Create an account
            </Link>
          </div>
          <div className="text-center text-sm">
            <Link className="text-black" to="/recovery">
              Forgot password?
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

Login.contextTypes = {
  router: PropTypes.object
};

export default Login;
