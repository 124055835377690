import React from "react";
import { withRouter, Link } from 'react-router-dom';
import pubsub from 'pubsub-js';
import { LinkContainer } from 'react-router-bootstrap';
import { Collapse, DropdownToggle, DropdownMenu, DropdownItem, Navbar, NavbarToggler, NavbarBrand, UncontrolledDropdown } from 'reactstrap';
import './Header.scss';

class Header extends React.Component {
  state = {
    navbarOpen: false,
    activePremise: null,
    premiseList: []
  }

  componentWillMount() {
    this.pubsub_premise = pubsub.subscribe('Premise.setActivePremise', (ev, premise) => {
      this.setState({ activePremise: premise });
    });
    this.pubsub_premise_list = pubsub.subscribe('Premise.setList', (ev, list) => {
      this.setState({ premiseList: list });
    });
  }

  componentWillUnmount() {
    pubsub.unsubscribe(this.pubsub_premise);
    pubsub.unsubscribe(this.pubsub_premise_list);
  }

  showSearch(e) {
    e.preventDefault();
    pubsub.publish('showsearch');
  }

  showSettings(e) {
    e.preventDefault();
    pubsub.publish('showsettings');
  }

  toggleNavbar = () => {
    this.setState({
      navbarOpen: !this.state.navbarOpen
    });
  }

  hideNavbar = () => {
    this.setState({
      navbarOpen: false
    });
  }

  render() {
    const { activePremise, premiseList } = this.state;

    return (
      <header className="header-container">
        <div className="container">
          <Navbar light expand="lg">
            <NavbarBrand href="/">
              <img src="/img/logo-horizontal.svg" alt="Logo" />
            </NavbarBrand>
            <NavbarToggler onClick={this.toggleNavbar} />
            <Collapse isOpen={this.state.navbarOpen} navbar>
              {
                activePremise !== null &&
                <ul className="navbar-nav mr-auto">
                  {
                    premiseList.length > 1 &&
                    <UncontrolledDropdown id="nav-premise-dropdown" tag="li" nav inNavbar>
                      <DropdownToggle nav className="active-premise">
                        {activePremise.address}&nbsp;<i className="ion-arrow-down-b dropdown-icon"></i>
                      </DropdownToggle>
                      <DropdownMenu className="md-dropdown-menu" >
                        {
                          premiseList.map((premise, key) => {
                            return (
                              <LinkContainer key={key} to={"/solar-view/" + premise.id + "/dashboard"} onClick={this.hideNavbar}>
                                <DropdownItem active={activePremise !== null && activePremise.id === premise.id}>
                                  {premise.address}
                                </DropdownItem>
                              </LinkContainer>
                            );
                          })
                        }
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  }
                  {
                    premiseList.length === 1 &&
                    <li className="nav-item d-none d-lg-block">
                      <Link to={"/solar-view/" + activePremise.id + "/dashboard"} className="nav-link active-premise" onClick={this.hideNavbar}>
                        {activePremise.address}
                      </Link>
                    </li>
                  }
                  {
                    activePremise.energyRateInited &&
                    <li className="nav-item">
                      <Link to={"/solar-view/" + activePremise.id + "/dashboard"} className="nav-link" onClick={this.hideNavbar}>
                        <em className="ion-grid icon"></em>&nbsp;Dashboard
                      </Link>
                    </li>
                  }
                  <li className="nav-item">
                    <Link to={"/solar-view/" + activePremise.id + "/settings"} className="nav-link" onClick={this.hideNavbar}>
                      <em className="ion-gear-b icon"></em>&nbsp;Settings
                    </Link>
                  </li>
                  {
                    activePremise.energyRateInited &&
                    <UncontrolledDropdown id="nav-premise-dropdown" tag="li" nav inNavbar>
                      <DropdownToggle nav>
                        <em className="ion-arrow-graph-up-right icon"></em>&nbsp;Reports&nbsp;<i className="ion-arrow-down-b dropdown-icon"></i>
                      </DropdownToggle>
                      <DropdownMenu className="md-dropdown-menu" >
                        <LinkContainer to={"/solar-view/" + activePremise.id + "/reports/power-diagram"} onClick={this.hideNavbar}>
                          <DropdownItem>
                            <em className="ion-stats-bars icon"></em>&nbsp;Power Diagram
                          </DropdownItem>
                        </LinkContainer>
                        <LinkContainer to={"/solar-view/" + activePremise.id + "/reports/heatmap"} onClick={this.hideNavbar}>
                          <DropdownItem>
                          <em className="ion-asterisk icon"></em>&nbsp;Heatmap
                          </DropdownItem>
                        </LinkContainer>
                        <LinkContainer to={"/solar-view/" + activePremise.id + "/reports/energy"} onClick={this.hideNavbar}>
                          <DropdownItem>
                            <em className="ion-flash icon"></em>&nbsp;Energy
                          </DropdownItem>
                        </LinkContainer>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  }
                </ul>
              }
              <ul className="navbar-nav float-right-lg ml-auto">
                <UncontrolledDropdown id="basic-nav-dropdown" tag="li" nav inNavbar>
                  <DropdownToggle nav className="has-badge ripple">
                    <em className="ion-person"></em>
                    <div className="d-lg-none">&nbsp;User menu</div>
                    {/* <sup className="badge bg-danger">3</sup> */}
                  </DropdownToggle>
                  <DropdownMenu right className="md-dropdown-menu" >
                    <LinkContainer to="/profile" onClick={this.hideNavbar}>
                      <DropdownItem>
                        <em className="ion-home icon-fw"></em>
                        Profile
                      </DropdownItem>
                    </LinkContainer>
                    {
                      premiseList.length > 0 &&
                      <LinkContainer to="/solar-view/add" onClick={this.hideNavbar}>
                        <DropdownItem>
                          <em className="ion-plus icon-fw"></em>
                          Link new SEAM
                        </DropdownItem>
                      </LinkContainer>
                    }
                    <LinkContainer to="/messages" onClick={this.hideNavbar}>
                      <DropdownItem>
                        <em className="ion-gear-a icon-fw"></em>
                        Messages
                      </DropdownItem>
                    </LinkContainer>
                    <DropdownItem divider />
                    <a href="https://seam.solar/support/" onClick={this.hideNavbar} target="_blank">
                      <DropdownItem>
                        <em className="ion-help-buoy icon-fw"></em>
                        Help &amp; Support
                      </DropdownItem>
                    </a>
                    <DropdownItem divider />
                    <LinkContainer to="/logout" onClick={this.hideNavbar}>
                      <DropdownItem>
                        <em className="ion-log-out icon-fw"></em>
                        Logout
                      </DropdownItem>
                    </LinkContainer>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </ul>
            </Collapse>
          </Navbar>
        </div>
      </header>
    );
  }
}

export default withRouter(Header);
